@import 'base';

@media print {

    html {
        font-size: 80%;
        box-sizing: border-box;
    }
    body {
        color: #000 !important;
        background: #fff !important;
        padding-top: 0;
    }

    .u-print-only {
        display: block !important;
    }
    .u-print-hidden {
        display: none !important;
    }
    .u-print-visible {
        display: block !important;
    }

    .l-Page__printLogo {
        float: right;
        margin: 0 0 20px 10px;
    }

    .l-Site__skipLinks,
    .l-Site__compatWarnings,
    .l-Site__masthead,
    .l-Site__footer,
    .l-Page__crumbs {
        display: none !important;
    }
    .l-Site__main {
        padding: 0 !important;
    }
    .l-Site__container {
        max-width: 100% !important;
        width: 100%;
        padding: 0 !important;
    }
    .l-Page__body {
        max-width: 100% !important;
        width: 100%;
    }

    .l-Page__title {
        font-size: rem(36);
    }

    .s-cmsContent {
        img {
            min-width: auto;
            page-break-inside: avoid;
        }

        p {
            page-break-inside: avoid;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            page-break-after: avoid;
        }
        h2 {
            font-size: rem(28);
        }
        h3 {
            font-size: rem(22);
        }
        h4 {
            font-size: rem(18);
        }
        h5 {
            font-size: rem(16);
        }
        h6 {
            font-size: rem(16);
        }

        img,
        .captionImage,
        .embed {

            &.left {
                float: left;
                margin-right: vr(2);
            }

            &.leftAlone {
                margin-right: auto;
            }

            &.right {
                float: right;
                margin-left: vr(2);
            }

            &.rightAlone {
                margin-left: auto;
            }

            &.center {
                margin: {
                    left: auto;
                    right: auto;
                }
            }
        }
    }

}
